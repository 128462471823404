import React, { useRef, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { staggerCats, unstaggerCats } from './Animations';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import Sportfolio from './Sportfolio';

const PortfolioCategories = ({ active }) => {
  //console.log(active);
  const yquery = useStaticQuery(graphql`
    query yQuery {
      allCatdesignYaml {
        nodes {
          category
          portfolio {
            description
            title
            link
            tag
            image {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `);
  const menu = yquery.allCatdesignYaml.nodes;

  const [currCat, setCurrCat] = useState();
  const [currPort, setCurrPort] = useState();
  const [pfolio, setPfolio] = useState(false);
  const [content, setContent] = useState();
  let LiRefs = useRef([]);
  let LiPrefs = useRef([]);

  useEffect(() => {
    if (active === true) {
      unstaggerCats(LiRefs.current);
    } else {
      staggerCats(LiRefs.current);
    }
  }, [active]);

  useEffect(() => {
    menu.map((port, i) => {
      if (port.category === currCat) {
        return setCurrPort(port.portfolio);
      } else {
        return null;
      }
    });
  }, [currCat, menu]);

  function setCategory(category) {
    gsap.to('#worklist', { y: 10, opacity: 0, duration: 0.5 });
    setTimeout(() => {
      setCurrPort();
    }, 500);
    gsap.to('#worklist', { y: 0, opacity: 1, duration: 0.5, delay: 0.5 });
    setTimeout(() => {
      setCurrCat(category);
    }, 500);
  }

  function openPortfolio(lcontent, event) {
    // console.log(lcontent, content);
    event.preventDefault();
    setContent(lcontent);
    setTimeout(() => {
      setPfolio(true);
    }, 500);
  }

  return (
    <>
      <Sportfolio
        content={content}
        pfolio={pfolio}
        setPfolio={setPfolio}
        setContent={setContent}
      />
      <div className="w-full h-full flex justify-center items-center realizzazioni">
        <div id="categories" className="w-full lg:w-4/6">
          {menu.map((D, i) => {
            return (
              <p
                aria-hidden="true"
                onClick={() => {
                  setCategory(D.category);
                }}
                key={i}
                ref={(el) => (LiRefs.current[i + 1] = el)}
                className="list-none text-6xl md:text-7xl lg:text-7xl xl:text-8xl font-bold relative overflow-hidden uppercase transform"
              >
                {D.category}
              </p>
            );
          })}
        </div>
      </div>
      <div
        id="worklist"
        className="w-100 flex flex-grow flex-wrap items-center mt-10"
      >
        {currPort &&
          currPort.map((P, i) => {
            return (
              <div key={i} className="w-full md:w-1/2 lg:w-1/3 p-5 ">
                {P.image && (
                  <GatsbyImage
                    image={P.image[0].childImageSharp.gatsbyImageData}
                    alt="test"
                  />
                )}
                <p
                  ref={(el) => (LiPrefs.current[i + 1] = el)}
                  className="list-none text-xl font-bold relative overflow-hidden uppercase transform"
                >
                  <a href={P.title} onClick={(e) => openPortfolio(P, e)}>
                    {P.title}
                  </a>
                </p>
                <div>{P.description}</div>

                {P.link && <span>{P.link}</span>}
                <div className="flex flex-wrap ">
                  {P.tag &&
                    P.tag.map((stag, itx) => {
                      return (
                        <div
                          id={itx}
                          key={itx}
                          className="flex justify-center items-center m-1 font-medium py-1 px-2 rounded-full text-gray-400 bg-gray-50 border border-gray-300 "
                        >
                          <div className="text-xs font-normal leading-none max-w-full flex-initial">
                            {stag}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PortfolioCategories;
