import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

const Scroll = () => {
  gsap.registerPlugin(ScrollToPlugin);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.to('#totp', {
      scrollTrigger: {
        trigger: '#scroller',
        start: 'top 85%',
        end: 'top 75%',
        toggleActions: 'play none none reverse',
      },
      opacity: 1,
      display: 'inline',
      duration: 1,
      delay: 0.3,
      ease: 'power3.inOut',
    });

    gsap.to('#sd', {
      scrollTrigger: {
        trigger: '#scroller',
        start: 'top 85%',
        end: 'top 75%',
        toggleActions: 'play none none reverse',
      },
      opacity: 0,
      display: 'none',
      duration: 1,
      ease: 'power3.inOut',
    });
  }, []);

  function toTop() {
    gsap.to(window, { duration: 0.5, scrollTo: 0 });
  }
  return (
    <div
      id="scroller"
      className="fixed text-sm bottom-0 right-0 uppercase p-3 mb-4 "
    >
      <div className="p-3 mb-4 ">
        <span
          id="sd"
          className=" p-3 mb-4 bg-white rounded-l-lg opacity-1 inline font-bold absolute -left-24"
        >
          Scroll Down
        </span>
        <span
          id="totp"
          onClick={toTop}
          className="opacity-0 hidden font-bold absolute -left-24 p-3 mb-4 bg-white rounded-l-lg"
        >
          Back to top
        </span>
      </div>
    </div>
  );
};

export default Scroll;
