import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
//import { slideOn } from '../components/Animations';

// import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import PortfolioCategories from '../components/PortfolioCategories';

import Cursor from '../components/Cursor';
import SeoBasic from '../components/SeoBasic';
import Colophon from '../components/Colophon';
import Scroll from '../components/Scroll';
// markup
const Realizzazioni = ({ transitionStatus }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (transitionStatus === 'exiting') {
      setActive(true);
    }
  }, [transitionStatus]);

  return (
    <main className="container mx-auto px-2 md:px-0">
      <SeoBasic slug="realizzazioni" />
      <Cursor />
      <Header />

      <PortfolioCategories active={active} />
      <Scroll />
      <Colophon />
    </main>
  );
};

export default Realizzazioni;
