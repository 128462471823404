import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles

import 'swiper/swiper.min.css';

const Sportfolio = ({ content, pfolio, setPfolio, setContent }) => {
  //console.log(content);
  SwiperCore.use([Autoplay]);

  let picRef = useRef([]);
  //let wrapperdiv = useRef();

  // const [loop, setLoop] = useState(0);
  // const [index, setIndex] = useState(0);
  function closePortfolio(e) {
    setContent();
    e.preventDefault();
    gsap.to('#port', { opacity: 0, visibility: 'hidden', duration: 0.5 });
    setTimeout(() => {
      setPfolio(false);
    }, 500);
  }

  useEffect(() => {
    if (content) {
      if (content.image.length > 1) {
      }
      gsap.to('#port', { autoAlpha: 1, duration: 0.3 });
      gsap.fromTo('.currimg', { scale: 0 }, { scale: 1, duration: 0.2 });
    } else {
      gsap.to('#port', { visibility: 'hidden', duration: 0 });
    }
    return () => {
      setPfolio(false);
    };
  }, [content, setPfolio]);
  return (
    <>
      <div
        id="port"
        className="w-screen flex-col fixed overflow-auto pt-2 pb-10 h-screen  bg-gray-800  top-0 left-0 z-50 bg-opacity-90"
      >
        {content && (
          <>
            <div className="absolute right-5 text-white font-bold z-50">
              <a href="close" onClick={(e) => closePortfolio(e)}>
                [x]
              </a>
            </div>
            <div className="relative" style={{ height: '60%' }}>
              <div className="container mx-auto px-2 md:px-0">
                <div className="uppercase text-xl text-center text-white font-bold mb-5">
                  {content.title}
                </div>
              </div>
              <Swiper
                autoplay={{ delay: 2200 }}
                pagination={{
                  el: '.swiper-pagination',
                }}
                spaceBetween={0}
                loop={true}
                speed={600}
                style={{
                  height: '50vh',
                }}
              >
                {content.image.map((simage, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <GatsbyImage
                        style={{
                          height: '100%',
                          /* width: calc(100% / 3); */
                          width: '100%',
                        }}
                        image={simage.childImageSharp.gatsbyImageData}
                        alt="test"
                        imgStyle={{
                          objectFit: 'contain',
                        }}
                        className="currimg"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </>
        )}

        <div
          style={{ marginTop: '40px' }}
          className="container mx-auto  bg-white p-5"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id voluptates
          temporibus reiciendis porro ducimus voluptas corrupti, laudantium
          quaerat impedit? Mollitia neque nam voluptatibus nobis similique
          tenetur debitis velit esse provident cupiditate. Iste assumenda sit,
          nesciunt provident inventore minima expedita esse, necessitatibus
          eveniet aspernatur alias nostrum, veritatis facere non nihil est.
        </div>
      </div>
    </>
  );
};

export default Sportfolio;
